<template>
  <section class="page_wrapper">
    <div class="container shopping">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="widget-title">Your orders</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <indexOrders/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import indexOrders from "@/components/IndexOrders";

export default {
  name: "UserArea",
  components : {
    indexOrders
  }
}
</script>

<style scoped>

</style>