<template>
  <div>
    <Image :homeImage="homeImage" />
    {{homeImage}}
    <ShowProducts />
  </div>
</template>

<script>
import Image from "@/components/Image";
import ShowProducts from "@/components/ShowProducts";

export default {
  name: "Home",
  components: { Image, ShowProducts },
  props: ['homeImage']
}
</script>

<style scoped>

</style>
