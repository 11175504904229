<template>
  <div class="product-item">
    <router-link :to="'/product/' + product.id" >
      <div class="product-image">
        <img v-if="product.images[0]" class="product-thumb" :src="product.images[0]?.imageUrl">
        <img v-else class="image" :src="PATH_APP + 'images/placeholder.jpg'" alt=""/>
        <div class="outofstock" v-if="!product.stock">OUT OF STOCK</div>
      </div>
    </router-link>
    <div class="product-content">
      <h4>
        <router-link :to="'/product/' + product.id " >
          <span v-if="typeof product.name !== 'undefined'">
            {{ product.name }}
          </span>
          <span v-else>
            {{ product.description }}
          </span>
        </router-link>
      </h4>
      <p class="price" v-if="product.discountprice"> <del>{{ formatPrice(product.price) }} </del> <span class="text-red">€ {{ formatPrice(product.discountprice) }} €</span></p>
      <p class="price" v-else> {{ formatPrice(product.price) }} €</p>
    </div>
  </div>
</template>

<script>
import {PATH_APP} from "@/constants/constants";

export default {
  name: "SingleProduct",
  props: ["product"], //modo per passare un oggetto come props
  data(){
    return {
      PATH_APP: PATH_APP
    }
  },
  methods: {
    formatPrice(value) {
      return Number(value)
          .toFixed(2)
          .replace(".", ",");
    }
  }
}
</script>

<style scoped>
.product-thumb{
  background-position: top center;
  background-size: cover;

}
.product-item{
  min-height: 300px;
  margin-bottom: 40px;
}
.product-image{
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;
}
.product-image .outofstock{
  width: 100%;
  position: absolute;
  overflow: hidden;
  bottom: 0;
  background-color: #dedede;
  text-align: center;
  color: #ffffff;
  padding: 6px;
  opacity: 0.9;
}
img {
  width: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit:scale-down;
}

.text-red{
  color: #e20036;
}
</style>