<template>
    <form class="checkout-form" @submit.prevent="checkForm()">
      <div class="alert alert-success" v-if="message">{{message}}</div>
      <div class="alert alert-danger mt-3" v-if="errors.length > 0" v-for="error in errors">
        {{error}}
      </div>
      <div class="row shipping-details">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="username" class="form-label">Company</label>
            <input type="text" class="form-control mb-3" v-model="reseller.company">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="username" class="form-label">Name</label>
            <input type="text" class="form-control mb-3" v-model="reseller.name">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="username" class="form-label">Surname</label>
            <input type="text" class="form-control mb-3" v-model="reseller.surname">
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label for="username" class="form-label">Name of shop</label>
            <input type="text" class="form-control mb-3" v-model="reseller.shopname">
          </div>
        </div>
        <div class="col-lg-8">
          <div class="form-group">
            <label for="username" class="form-label">Address</label>
            <input type="text" class="form-control mb-3" v-model="reseller.address">
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group">
            <label for="username" class="form-label">Zipcode</label>
            <input type="text" class="form-control mb-3" v-model="reseller.zipcode">
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="username" class="form-label">City</label>
            <input type="text" class="form-control mb-3" v-model="reseller.city">
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="username" class="form-label">State/Prov</label>
            <input type="text" class="form-control mb-3" v-model="reseller.state">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="username" class="form-label">Country</label>
            <input type="text" class="form-control mb-3" v-model="reseller.country">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="username" class="form-label">Phone</label>
            <input type="text" class="form-control mb-3" v-model="reseller.phone">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label for="username" class="form-label">E-mail</label>
            <input type="text" class="form-control mb-3" v-model="reseller.email">
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label for="username" class="form-label">Brands</label>
            <input type="text" class="form-control mb-3" v-model="reseller.brands">
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <label for="username" class="form-label">How is 1 + 5?</label>
            <input type="text" class="form-control mb-3" placeholder="Write the result here..." v-model="reseller.test">
          </div>
        </div>
        <div class="col-lg-12">
          <div class="form-group">
            <textarea class="form-control mb-3" rows="5" placeholder="Message" v-model="reseller.notes"></textarea>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-brand">Send</button>
    </form>
</template>

<script>
import Menu from './Menu'
import axios from "axios";

export default {
  name: "Header",
  data(){
    return {
      reseller : {},
      message : null,
      errors : []
    }
  },
  components : {
    Menu
  },
  methods : {
    checkForm(){
      this.errors = [];
      if (this.reseller.company && this.reseller.name && this.reseller.surname) {
        this.sendRequest();
      }
      if (!this.reseller.company || !this.reseller.name) {
        this.errors.push('All fields are required');
      }
    },
    async sendRequest(){
      this.$store.commit('showSpinner');
      await axios.post("sendreseller", {
        company : this.reseller.company,
        name : this.reseller.name,
        surname : this.reseller.surname,
        shopname : this.reseller.shopname,
        address : this.reseller.address,
        zipcode : this.reseller.zipcode,
        city : this.reseller.city,
        state : this.reseller.state,
        country : this.reseller.country,
        phone : this.reseller.phone,
        email : this.reseller.email,
        brands : this.reseller.brands,
        notes : this.reseller.notes,
        test : this.reseller.test,
      }).then((resp) => {
        this.$store.commit('hideSpinner');
        this.message = "Message has been sent correctly.";
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

</style>