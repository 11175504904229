<template>
  <div id="carouselExample" class="carousel slide" data-bs-ride="carousel" :class="[data.columnb, data.classes]">
      <div class="carousel-inner">
        <div v-for="(item, index) in data" :key="index" :class="['carousel-item', { 'active': index === 0 }]">
          <img :src="item.url" class="d-block w-100" alt="Slide Image">
          <div class="carousel-caption d-none d-md-block">
            <h5>{{ index }}</h5>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
</template>

<script>
export default {
  props:['data'],
  data() {
    return {
    };
  }
};
</script>

<style scoped>
.carousel{
  height: 50%;
}
#carouselExample{
  padding:0;
}
/* Add custom styles if needed */
</style>
