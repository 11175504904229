<template>
  <div class="page_wrapper">
    <div class="container shopping">
      <div class="row">
        <div class="col-lg-12">
          <h1 class="widget-title">Resellers Module</h1>
          <small>All fields are required</small>
          <br><br>
        </div>
      </div>
      <ResellersForm/>
    </div>
  </div>
</template>

<script>
import ResellersForm from "@/components/ResellersForm";
export default {
  name: "Resellers",
  components: {
    ResellersForm
  }
}
</script>

<style scoped>
small{
  color:red;
}
</style>