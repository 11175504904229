<template>
  <div class="btn-menu" v-on:click="toggleMenu()">
    <font-awesome-icon icon="bars"/>
  </div>
  <div class="menu-overlay" v-on:click="toggleMenu()" :class="isOpen ? 'active' : null"></div>
  <div class="sidebarmenu" :class="isOpen ? 'active' : null">
    <div class="closemenu" v-on:click="toggleMenu()">
      <font-awesome-icon icon="remove"/>
    </div>
    <div class="d-flex justify-content-around menutop mt-3 mb-3">
      <router-link to="/search" v-on:click="toggleMenu()" class="link">
        <font-awesome-icon icon="search"/>
      </router-link>
      <router-link to="/favourites" v-on:click="toggleMenu()" class="link">
        <font-awesome-icon :icon="['far', 'heart']"/>
      </router-link>
    </div>
    <ul class="menu">
      <li>
        <router-link to="/" v-on:click="toggleMenu()" class="link">SHOP</router-link>
      </li>
      <li v-for="category in categories" :key="category.id">
        <router-link :to="'/category/' + category.id" v-on:click="toggleMenu()" class="link">{{ category.description }}</router-link>
      </li>
      <li>
        <router-link to="/brand" v-on:click="toggleMenu()" class="link">BRAND</router-link>
      </li>
      <li>
        <router-link to="/contacts" v-on:click="toggleMenu()" class="link">CONTACTS</router-link>
      </li>
      <li v-if="!isAuth">
        <div class="parent justify-content-center">
          <router-link to="/login" v-on:click="toggleMenu()" class="link">
            LOGIN
          </router-link>
        </div>
      </li>
      <template v-else>
        <li>
          <div class="parent justify-content-center">
            <router-link class="link" to="userarea" v-on:click="toggleMenu()">{{user.name}}</router-link>
          </div>
        </li>
        <li>
          <div class="parent justify-content-center">
            <a class="link" v-on:click="toggleMenu(), logout()">
              Logout
            </a>
          </div>
        </li>
      </template>
    </ul>
    <!--<ul class="menubottom">
      <li>
        <form @submit.prevent="search">
          <input type="text" placeholder="Search..." class="form-control mt-3 mb-3 search" v-model="query">
        </form>
      </li>
    </ul>-->
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";

export default {
  name: "Menu",
  data() {
    return {
      isOpen: false,
      user : null,
      query : null,
    }
  },
  computed : {
    categories() {
      return this.$store.getters.getCategories;
    },
    filterCategory(){
      return this.$store.getters.getFilterCategory;
    },
    isAuth(){
      return this.$store.getters.getAuth;
    }
  },
  beforeMount(){
    let user = localStorage.getItem("user");
    if(user && user !== "null") {
      this.user = JSON.parse(user);
    }else{
      this.logout();
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen ? this.isOpen = false : this.isOpen = true;
    },
    async logout(){
      await this.$store.dispatch("Logout");
    },
    search(){
      this.isOpen = false;
      router.push("search?s=" + this.query);
      this.query = null;
      //document.location.href = PATH_APP + "search?s=" + this.query;
    }
  }
}
</script>

<style scoped>
.parent {
  text-align: center;
}

.active {
  display: block !important;
}

.sidebarmenu{
  overflow: scroll;
}

.btn-menu svg {
  font-size: 28px;
}

.btn-menu svg:hover {
  cursor: pointer;
}

.menu-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: none;
}

.sidebarmenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  z-index: 999;
}

.menu {
  padding: 10px !important;
  position: relative;
  display: block;
  list-style: none;
  overflow: hidden;
}

.menu li {
  float: left;
  width: 100%;
  border-bottom: 1px solid #dedede;
  margin-left: 0 !important;
}

.menu li a {
  display: block;
  padding: 20px;
  text-align: left;
  color: #000;
  font-size: 17px;
}

.closemenu {
  text-align: center;
  padding: 50px;
}

.closemenu svg {
  font-size: 30px;
}

.closemenu:hover {
  cursor: pointer;
}

.btn-menu {
  padding-top: 26px;
}

@media (max-width: 768px) {
  .btn-menu {
    padding-top: 35px;
  }
}

.search{
  padding: 20px;
  font-size: 18px;
  border: none;
}

.menubottom{
  margin-top: 20px;
  padding: 10px !important;
  position: relative;
  display: block;
  list-style: none;
  overflow: hidden;
  margin-bottom: 0;
}

.menubottom li{
  border: none;
}

.menubottom li input{
  border: 1px solid #dedede;
}

.menutop svg{
  font-size: 25px;
}

</style>