<template>
  <div class="table-responsive" v-if="orders.length">
    <table class="table">
      <thead class="bg-dark text-white">
        <tr>
          <th>Order</th>
          <th>Status</th>
          <th>Payment</th>
          <th>Total</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders">
          <td class="align-middle">#{{order.idorder}}</td>
          <td class="align-middle">
            <span class="badge bg-warning p-2">{{order.status}}</span>
          </td>
          <td class="align-middle">
            <span class="badge bg-dark p-2">
              <template v-if="order.payment.type === 'braintree'">Credit card</template>
              <template v-if="order.payment.type === 'paypal'">PayPal</template>
            </span>
          </td>
          <td class="align-middle">{{order.total}} €</td>
          <td class="align-middle d-flex flex-row">
            <router-link :to="'/orderdetails/' + order.idorder" class="btn btn-sm btn-brand mb-2">Details</router-link>
            <router-link :to="'/checkout/' + order.idorder" class="btn btn-sm btn-brand mb-2" v-if="order.status === 'pending'">Pay</router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="alert alert-info" v-else>
    There are no orders
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CheckoutForm",
  data() {
    return {
      orders : []
    }
  },
  async beforeMount(){
    this.$store.commit('showSpinner');
    let token = localStorage.getItem("token");
    await axios.get("orders", {
      headers: { Authorization: `Bearer ${token}` }
    }).then((resp) => {
      this.$store.commit('hideSpinner');
      this.orders = resp.data.orders;
    }).catch((error) => {
      this.$store.commit('hideSpinner');
      console.log(error);
    });
  },
  methods: {}
}
</script>

<style scoped>
.btn{
  display: inline-block;
  margin-right: 5px;
}
</style>