<template>
  <section class="page_wrapper">
    <ShowProducts :search="true"/>
  </section>
</template>

<script>
import ShowProducts from "@/components/ShowProducts";

export default {
  name: "Search",
  components: {ShowProducts}
}

</script>

<style scoped>

</style>


