<template>
  <div class="page_wrapper">
    <div class="checkout shopping">
      <div class="container">
        <CheckoutForm/>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutForm from "@/components/CheckoutForm";

export default {
  name: "Checkout",
  components:{
    CheckoutForm
  }
}
</script>

<style scoped>

</style>