import axios from "axios";
import router from "@/router";
import Swal from "sweetalert2";
import {PRODUCTS_NUM, PATH_APP, BASE_BACKEND_URL} from "@/constants/constants";

export default {
    setSpinnerLoading({commit}, status){
        commit('SET_SPINNER_LOADING', status);
    },
    setStatusSearch({commit}, query){
        commit('SET_STATUS_SEARCH', query);
    },
    emptyProducts({commit}){
        commit('EMPTY_PRODUCTS');
    },
    emptySearchProducts({commit}){
        commit('EMPTY_PRODUCTS_SEARCH');
    },
    emptyWishProducts({commit}){
        commit('EMPTY_WISH_PRODUCTS');
    },
    async getProducts({commit, dispatch}, params) {
        dispatch("setSpinnerLoading", true);
        try {
            let {data} = await axios.post("products", {
                "start": params.start,
                "limit": PRODUCTS_NUM,
                "type" : "website",
                "description" : params.query,
                "idsProduct" : params.idsProduct,
                "idsCategory": params.idsCategory ? params.idsCategory : null
            });
            if(params.query && params.query !== "") {
                commit('SET_PRODUCTS_SEARCH', data);
            }else if(params.idsProduct){
                commit('SET_PRODUCTS_WISH', data);
            }else{
                commit('SET_PRODUCTS', data);
            }
            dispatch("setSpinnerLoading", false);
        } catch (error) {
            dispatch("setSpinnerLoading", false);
            console.log(error);
        }
    },
    async getCategories({commit, state}, params) {
        try {
            let {data} = await axios.get("categories", {
                params: {
                    "start": 0,
                    "limit": 100
                }
            });
            commit("SET_CATEGORIES", data.categories);
        } catch (error) {
            console.log(error);
        }
    },
    async addToCart({commit}, cart) {
        try {
            commit('showSpinner');
            let idcart = localStorage.getItem("cartIdBKL");
            let config = {};
            let token = localStorage.getItem("token");
            if(token){
                idcart = null;
                config = {
                    headers: { Authorization: `Bearer ${token}` }
                };
            }
            let res = await axios.post("addtocart", {
                idcart: idcart,
                idproduct: cart.idproduct,
                qnt: cart.qnt,
                productType: cart.type,
                subproducts : cart.subproducts,
                gift : cart.gift
            },config);
            commit('SET_CART_ID', res.data.cart);
            commit('SET_TOTAL_PRODSCART', res.data.totalProdsCart);
            commit('hideSpinner');
            Swal.fire({
                showCancelButton: true,
                title: 'Product added to shopping bag',
                icon: 'success',
                iconColor: '#000',
                confirmButtonText: 'View shopping bag',
                cancelButtonText: "Continue shopping",
                confirmButtonColor: '#e20036',
            }).then((result) => {
                if (result.isConfirmed) {
                    router.push('/cart');
                }else if(result.isDismissed){
                    router.push('/');
                }
            });
        } catch (error) {
            commit('hideSpinner');
            console.log(error);
        }
    },
    async Logout({commit}) {
        let token = localStorage.getItem("token");
        await axios.get('logout',{
            headers: { Authorization: `Bearer ${token}` }
        }).then((res) => {
            console.log(res);
            //faccio logout a prescindere
            localStorage.removeItem('token');
            localStorage.removeItem('refreshtoken');
            localStorage.removeItem("expires");
            localStorage.removeItem("user");
            commit("SET_AUTH", false);
            router.push('/login');
        }).catch((error) => {
            console.log(error);
        });
    },

    //CMS
    async fetchMenus({commit}) {
        try {
            const response = await axios.get(
                BASE_BACKEND_URL + 'api/cms/v1/menus',
            );
            console.log('menus',response.data.menus);
            commit("SET_MENUS", response.data.menus)
            //this.menus = response.data.menus;
        } catch (error) {
            console.error("Error fetching menus:", error);
        }
    },
    //serve per prendere gli hooks e settare l'immagine della home
    async components({ commit }, isLoadingImage) {
        try {
            const response = await axios.get(BASE_BACKEND_URL + 'api/cms/v1/components');
            // Filtra per componenti senza pagine associate
            commit("SET_PAGEDATA", response.data.components.filter(component => !component.title && !component.slug));
            //commit("SET_LOADING_IMAGE", isLoadingImage); // Imposta isLoadingImage a false l'immagine è stata caricata
        } catch (error) {
            console.error(error);
        }
    },
    //CMS
}