<template>
  <footer class="footer section text-center">
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <img class="logo-main" :src="base + '/images/logo.png'" style="max-height: 77px;">
          <ul class="social-media d-flex justify-content-center ">
            <li>
              <a target="_blank" href="https://it-it.facebook.com/BikiniLovers.it/">
                <i class="tf-ion-social-facebook"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.instagram.com/bikinilovers/">
                <i class="tf-ion-social-instagram"></i>
              </a>
            </li>
          </ul>
          <ul class="footer-menu text-uppercase">
            <!--li>
              <router-link to="/">SHOP</router-link>
            </li>
            <li>
              <router-link to="/brand">BRAND</router-link>
            </li>
            <li>
              <router-link to="/contacts">CONTACTS</router-link>
            </li>
            <li>
              <router-link to="/privacy-policy">PRIVACY POLICY</router-link>
            </li>
            <li>
              <router-link to="/contacts">TERMS AND CONDITIONS</router-link>
            </li-->
            <li v-for="(menuItem, index) in menus.menuitems" :key="index">
              <router-link :to="`${menuItem.link}`" class="link">
                {{ menuItem.name}}
              </router-link>
              <ul v-for="(itemschild, index) in menuItem.itemschild" :key="index">
                <router-link :to="`${menuItem.link}`" class="link">
                  {{ itemschild.name}}
                </router-link>
              </ul>
            </li>
          </ul>
          <p class="copyright-text">
            &copy;2024 - BKL Srls<br>
            P.IVA 07632221219
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {PATH_APP} from '@/constants/constants';
export default {
  name: "Footer",
  props: ['menus'],
  data(){
    return{
      base: PATH_APP
    }
  }
}
</script>

<style scoped>
.logo-main{
  width: 70px;
  position: page;
}

.footer{
  padding: 30px;
}
</style>