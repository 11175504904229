<template>
  <section class="page_wrapper">
    <div class="container">
      <div class="row" v-if="mex === 1">
        <div class="col-lg-12 text-center">
          <h4>Order submitted</h4>
          <br>
          <div class="alert alert-success">
            Your order is submitted correctly. You will receive an e-mail when your order will be shipped.
          </div>
          <router-link to="/" class="btn btn-brand">Continue to shop</router-link>
        </div>
      </div>
      <div class="row" v-if="mex === 2">
        <div class="col-lg-12 text-center">
          <h4>Order problems</h4>
          <br>
          <div class="alert alert-danger">
            Your order is not submitted correctly. Please try later.
          </div>
          <router-link to="/" class="btn btn-brand">Continue to shop</router-link>
        </div>
      </div>
      <div class="row" v-if="mex === 3">
        <div class="col-lg-12 text-center">
          <h4>Password changed</h4>
          <br>
          <div class="alert alert-success">
            Your password has been changed.
          </div>
          <router-link to="/login" class="btn btn-brand">Continue to login</router-link>
        </div>
      </div>
      <div class="row" v-if="mex === 4">
        <div class="col-lg-12 text-center">
          <h4>Payment successful</h4>
          <br>
          <div class="alert alert-success">
            Your payment has been successful.
          </div>
        </div>
      </div>
      <div class="row" v-if="mex === 5">
        <div class="col-lg-12 text-center">
          <h4>Payment refused</h4>
          <br>
          <div class="alert alert-danger">
            Your payment has been refused or there are several technical problems. Please retry later.
          </div>
        </div>
      </div>
      <div class="row" v-if="mex === 6">
        <div class="col-lg-12 text-center">
          <h4>Order paid</h4>
          <br>
          <div class="alert alert-danger">
            The order has already been paid.
          </div>
          <router-link to="/" class="btn btn-brand">Return to shop</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Messages",
  data(){
    return {
      mex : parseInt(this.$route.params.mex)
    }
  }
}
</script>

<style scoped>

</style>