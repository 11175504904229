<template>
  <div class="alert alert-success" v-if="message">{{message}}</div>
  <div class="alert alert-danger mt-3" v-if="errors.length > 0" v-for="error in errors">
    {{error}}
  </div>
  <form class="text-left clearfix" @submit.prevent="checkForm()">
    <p>
      Please enter the email address for your account. A verification code will be sent to you.
      Once you have received the verification code, you will be able to choose a new password for your account.
    </p>
    <div class="form-group">
      <label class="form-label">E-mail <strong>*</strong></label>
      <input type="email" class="form-control mb-3" v-model="email">
    </div>
    <div class="form-group">
      <button type="submit" class="btn btn-brand btn-sm">Reset password</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "RecoverForm",
  data(){
    return {
      email : null,
      remember : false,
      message : null,
      errors : [],
    }
  },
  methods: {
    checkForm(){
      this.errors = [];
      if (this.email) {
        this.recoverPassword();
      }
      if (!this.email) {
        this.errors.push('Email required');
      }
    },
    async recoverPassword(){
      this.$store.commit('showSpinner');
      axios.post("recover", {
        email : this.email,
        type : "website"
      }).then((response) => {
        this.$store.commit('hideSpinner');
        this.message = response.data.message;
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        if(error.response.status === 400) {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Technical problems to recover password.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#e20036',
          });
          console.log(error);
        }else{
          console.log(error);
        }
      });
    }
  }
}
</script>

<style scoped>

strong{
  color: red;
}

</style>