<template>
  <div class="btn-menu" v-on:click="toggleMenu()">
    <font-awesome-icon icon="bars"/>
  </div>
  <div class="menu-overlay" v-on:click="toggleMenu()" :class="isOpen ? 'active' : null"></div>
  <div class="sidebarmenu" :class="isOpen ? 'active' : null">
    <div class="closemenu" v-on:click="toggleMenu()">
      <font-awesome-icon icon="remove"/>
    </div>
    <div class="d-flex justify-content-around menutop mt-3 mb-3">
      <router-link to="/search" v-on:click="toggleMenu()" class="link">
        <font-awesome-icon icon="search"/>
      </router-link>
      <router-link to="/favourites" v-on:click="toggleMenu()" class="link">
        <font-awesome-icon :icon="['far', 'heart']"/>
      </router-link>
    </div>
    <ul class="menu">
      <li>
        <router-link to="/" v-on:click="toggleMenu()" class="link">SHOP</router-link>
      </li>
      <!--<li v-for="category in categories" :key="category.id">
        <router-link :to="'/category/' + category.id" v-on:click="toggleMenu()" class="link">{{ category.description }}</router-link>
      </li>-->
      <li v-for="(menuItem, index) in sidebarmenu.menuitems" :key="index">
        <router-link :to="`${menuItem.link}`" v-on:click="toggleMenu()"
                     class="link">
          {{ menuItem.name}}
        </router-link>
        <ul v-for="(itemschild, index) in menuItem.itemschild" :key="index">
          <router-link :to="`${itemschild.link}`" v-on:click="toggleMenu()"
                       class="ms-4 link">
            {{ itemschild.name}}
          </router-link>
        </ul>
      </li>
      <li v-if="!isAuth">
        <div class="parent justify-content-center">
          <router-link to="/login" v-on:click="toggleMenu()" class="link">
            LOGIN
          </router-link>
        </div>
      </li>
      <template v-else>
        <li>
          <div class="parent justify-content-center">
            <router-link class="link" to="userarea" v-on:click="toggleMenu()">{{user?.name}}</router-link>
          </div>
        </li>
        <li>
          <div class="parent justify-content-center">
            <a class="link" v-on:click="toggleMenu(); logout();">
              Logout
            </a>
          </div>
        </li>
      </template>
    </ul>
    <div class="text-center">Seguici su:</div>
    <ul class="socialmenu">
      <li>
        <a target="_blank" href="https://www.instagram.com/bikinilovers/">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
          </svg>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "CmsMenu",
  props: ['sidebarmenu'],
  data() {
    return {
      isOpen: false,
      user : null,
      query : null,
    }
  },
  computed : {
    categories() {
      return this.$store.getters.getCategories;
    },
    filterCategory(){
      return this.$store.getters.getFilterCategory;
    },
    isAuth(){
      return this.$store.getters.getAuth;
    }
  },
  beforeMount(){
    let user = localStorage.getItem("user");
    if(user && user !== "null") {
      this.user = JSON.parse(user);
    }else{
      //questa linea di codice non serve perché se non c'é l'utente è già stato fatto il logout
      //this.logout();
    }
  },
  methods: {
    toggleMenu() {
      this.isOpen ? this.isOpen = false : this.isOpen = true;
    },
    async logout(){
      await this.$store.dispatch("Logout");
    },
    search(){
      this.isOpen = false;
      router.push("search?s=" + this.query);
      this.query = null;
      //document.location.href = PATH_APP + "search?s=" + this.query;
    }
  }
}


</script>

<style scoped>
.parent {
  text-align: center;
}

.active {
  display: block !important;
}

.sidebarmenu{
  overflow: scroll;
}

.btn-menu svg {
  font-size: 28px;
}

.btn-menu svg:hover {
  cursor: pointer;
}

.menu-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  display: none;
}

.sidebarmenu {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  z-index: 999;
}

.menu {
  padding: 10px !important;
  position: relative;
  display: block;
  list-style: none;
  overflow: hidden;
}

.menu li {
  float: left;
  width: 100%;
  border-bottom: 1px solid #dedede;
  margin-left: 0 !important;
}

.menu li a {
  display: block;
  padding: 15px;
  text-align: left;
  color: #000;
  font-size: 15px;
}

.closemenu {
  text-align: center;
  padding: 30px;
}

.closemenu svg {
  font-size: 30px;
}

.closemenu:hover {
  cursor: pointer;
}

.btn-menu {
  padding-top: 26px;
}

@media (max-width: 768px) {
  .btn-menu {
    padding-top: 35px;
  }
}

.search{
  padding: 20px;
  font-size: 18px;
  border: none;
}

.menubottom{
  margin-top: 20px;
  padding: 10px !important;
  position: relative;
  display: block;
  list-style: none;
  overflow: hidden;
  margin-bottom: 0;
}

.menubottom li{
  border: none;
}

.menubottom li input{
  border: 1px solid #dedede;
}

.menutop svg{
  font-size: 25px;
}

.socialmenu{
  overflow: hidden;
  width: 100%;
  padding: 10px !important;
}

.socialmenu li a{
  text-align: center;
}

.socialmenu li{
  border-bottom: 0;
}

.socialmenu svg{
  width: 50px;
}

</style>