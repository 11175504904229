<template>
  <section class="page_wrapper">
    <div class="container shopping">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="widget-title">Order details</h4>
          <table class="table table-borderless">
            <thead class="bg-dark text-white">
            <tr>
              <th>Image</th>
              <th>Description</th>
              <th>Qnt</th>
              <th>Subtotal</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="item in order.details">
                <td rowspan="3" class="thumbnail-detail" v-if="item.type === 'compound' && !item.idcompound">
                  <img class="img-fluid" :src="item.image">
                </td>
                <td class="thumbnail-detail" v-else-if="item.type === 'product' && !item.idcompound">
                  <img class="img-fluid" :src="item.image">
                </td>
                <template v-if="item.type === 'product' && item.idcompound">
                  <td colspan="3" class="align-middle indentation">
                    <small>{{item.productname}}</small>
                  </td>
                </template>
                <template v-else>
                  <td class="align-middle">{{item.productname}}</td>
                  <td class="align-middle">{{item.qnt}}</td>
                  <td class="align-middle">{{item.unitprice}} €</td>
                </template>
              </tr>
            </tbody>
            <tfoot>
            <tr v-if="order.gift && order.gift !== 'null'">
              <td align="right" colspan="3">
                Confezione regalo
              </td>
              <td align="right">
                {{2.00}} €
              </td>
            </tr>
            <tr v-if="order.totalgifts && order.totalgifts !== 'null'">
              <td align="right" colspan="3">
                Totale confezioni regalo
              </td>
              <td align="right">
                {{order.totalgifts}} €
              </td>
            </tr>
            <tr v-if="order.shippingprice && order.shippingprice !== 'null'">
              <td align="right" colspan="3">
                Spese di spedizione
              </td>
              <td align="right">
                {{order.shippingprice}} €
              </td>
            </tr>
            <tr v-if="order.coupon && order.coupon !== 'null'">
              <td align="right" colspan="3">
                Coupon sconto {{order.coupon?.couponcode}}
              </td>
              <td align="right">
                <template v-if="order.coupon?.type === 'amount'">
                  {{order.coupon.value}} €
                </template>
                <template v-else>
                  {{order.coupon.value}} %
                </template>
              </td>
            </tr>
            <tr>
              <td align="right" colspan="3">Totale ordine</td>
              <td align="right" class="gray">{{order.total}} €</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "OrderDetails",
  data() {
    return {
      order: []
    }
  },
  async beforeMount() {
    this.$store.commit('showSpinner');
    let token = localStorage.getItem("token");
    await axios.get("orders/" + this.$route.params.idorder, {
      headers: { Authorization: `Bearer ${token}` }
    }).then((resp) => {
      this.$store.commit('hideSpinner');
      this.order = resp.data.order;
    }).catch((error) => {
      this.$store.commit('hideSpinner');
      console.log(error);
    });
  },
  methods: {

  }
}
</script>

<style scoped>
table tfoot td{
  font-weight: bold;
}

table tbody tr{
  border-bottom: 1px solid #dedede;
}

.indentation{
  padding-left: 10px;
}

.thumbnail-detail{
  width: 100px;
}

.thumbnail-detail img{
  padding: 5px;
}

.indentation small{
  font-size: 12px;
}
</style>