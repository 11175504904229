<template>
  <div class="form-paypal">
    <p>Click the button below to pay the order on PayPal's secure servers.</p>
    <img class="img-fluid logopaypal" :src="PATH_APP + 'images/paypal-logo.png'">
    <form :action="PAYPAL_URL" method="POST">
      <input type="hidden" name="cmd" value="_xclick">
      <input type="hidden" name="business" :value="PAYPAL_ACCOUNT">
      <input type="hidden" name="item_name" :value="'Bikini Lovers - Ordine numero ' + order.idorder">
      <input type="hidden" name="item_number" :value="order.idorder">
      <input type="hidden" name="amount" :value="order.total">
      <input type="hidden" name="custom" :value="order.idorder">
      <input type="hidden" name="return" :value="BASE_BACKEND_URL + 'api/v1/website/returnpaypal'">
      <input type="hidden" name="cancel_return" :value="BASE_BACKEND_URL + 'api/v1/website/cancelreturnpaypal'">
      <input type="hidden" name="notify_url" :value="BASE_BACKEND_URL + 'payment/ipn'">
      <input type="hidden" name="quantity" value="1">
      <input type="hidden" name="lc" value="IT">
      <input type="hidden" name="rm" value="2">
      <input type="hidden" name="cs" value="0">
      <input type="hidden" name="cbt" value="Continua">
      <input type="hidden" name="currency_code" value="EUR">
      <button class="paypalbutton btn btn-brand" type="submit">Pay now</button>
    </form>
  </div>
</template>

<script>
import {PATH_APP, PAYPAL_URL, PAYPAL_ACCOUNT, BASE_BACKEND_URL} from '@/constants/constants';

export default {
  name: "PaypalPayment",
  props : {
    order : {
      type : Object,
      required: true,
    }
  },
  data() {
    return {
      PAYPAL_URL : PAYPAL_URL,
      PAYPAL_ACCOUNT: PAYPAL_ACCOUNT,
      BASE_BACKEND_URL : BASE_BACKEND_URL,
      PATH_APP : PATH_APP,
      errors : []
    }
  }
}
</script>

<style scoped>
.form-paypal{
  text-align: center;
  padding: 30px 10px 30px 10px;
  border: 1px solid #dedede;
}
.paypalbutton{
  padding: 10px;
}

.logopaypal{
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 300px;
}
</style>