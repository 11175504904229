<template xmlns="http://www.w3.org/1999/html">
  <section class="page_wrapper">
    <div class="container shopping cart" v-if="cart?.lineItems?.length">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="widget-title">Shopping bag</h4>
        </div>
      </div>
      <div class="row mt-5" v-for="lineItem in cart?.lineItems" :key="lineItem?.id">
        <div class="col-lg-2 col-12 text-center">
          <router-link :to="'/product/' + lineItem.id">
            <img v-if="lineItem.product.image" :src="lineItem.product.image" class="img-fluid cart-image mb-3" alt="Generic placeholder image">
            <img v-else :src="base + 'images/placeholder.jpg'" alt="" class="img-fluid cart-image mb-3"/>
          </router-link>
        </div>
        <div class="col-lg-3 col-6">
          <div>
            <p class="small heading-cart">Name</p>
            <p class="lead fw-normal mb-0">
              <h5>
                <router-link class="link" :to="'/product/' + lineItem.id">{{ lineItem.product?.name }}</router-link>
              </h5>
              <template v-for="sub in lineItem.subproducts">
                <small>{{sub.name}}</small><br>
              </template>
              <small v-if="lineItem.gift"><b>Confezione regalo: € 2,00</b></small>
            </p>
          </div>
        </div>
        <div class="col-lg-1 col-6">
          <div>
            <p class="small heading-cart">Quantity</p>
            <p class="lead fw-normal mb-0">{{lineItem.qnt}}</p>
          </div>
        </div>
        <div class="col-lg-2 col-6">
          <div>
            <p class="small heading-cart">Price</p>
            <p class="lead fw-normal mb-0">€ {{ this.formatPrice(lineItem.product?.unitPrice) }}</p>
          </div>
        </div>
        <div class="col-lg-2 col-6">
          <div>
            <p class="small heading-cart">Total</p>
            <p class="lead fw-normal mb-0">€ {{ this.formatPrice(lineItem.qnt * lineItem.product?.unitPrice) }}</p>
          </div>
        </div>
        <div class="col-lg-2 col-6">
          <div>
            <p class="small heading-cart">Actions</p>
            <button class="btn btn-sm btn-brand" v-on:click="removeFromCart(lineItem.id, lineItem.subproducts)">Remove</button>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="d-flex justify-content-between">
          <h5>TOTAL</h5>
          <h5>€ {{this.formatPrice(this.cart?.totals?.total)}}</h5>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-lg-12 text-center mt-3">
          <router-link to="/checkout" class="btn btn-brand"><b>Checkout</b></router-link><br>
        </div>
        <div class="col-lg-12 text-center mt-3">
          <button type="button" class="btn-empty-cart" v-on:click="this.emptyCart()">Empty cart</button>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="row">
        <div class="col-lg-12 text-center">
          <h4>Your CART is empty!</h4>
          <br>
          <router-link to="/" class="btn btn-brand">Go to shopping</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import {PATH_APP} from "@/constants/constants";

export default {
  name: "Cart",
  data(){
    return {
      idcart : localStorage.getItem("cartIdBKL"),
      cart : [],
      base : PATH_APP
    }
  },
  mounted() {
    this.getCart();
  },
  methods: {
    formatPrice(value) {
      return Number(value/100)
          .toFixed(2)
          .replace(".", ",");
    },
    async getCart() {
      this.$store.commit('showSpinner');
      let idcart = localStorage.getItem("cartIdBKL");
      let token = localStorage.getItem("token");
      await axios.get("cart?idcart=" + idcart, {
        headers: { Authorization: `Bearer ${token}` }
      }).then((resp) => {
        this.$store.commit('hideSpinner');
        this.cart = resp.data.cart;
        if(!localStorage.getItem("cartIdBKL")){
          localStorage.setItem("cartIdBKL", resp.data.cart.id);
        }
      }).catch((error) => {
        this.$store.commit('hideSpinner');
        if(error.response.status === 404) {
          localStorage.removeItem("cartIdBKL");
        }else{
          console.log(error);
        }
      });
    },
    async removeFromCart(idprod, subproducts){
      let idcart = localStorage.getItem("cartIdBKL");
      let token = localStorage.getItem("token");
      await axios.delete("removefromcart",{
        headers: { Authorization: `Bearer ${token}` },
        data : {
          idcart : idcart,
          id : idprod,
          subproducts : subproducts
        }
      }).then((resp) => {
        this.cart = resp.data.cart;
        this.$store.commit('SET_TOTAL_PRODSCART', resp.data.totalProdsCart);
      }).catch((error) => {
        console.log(error);
      });
    },
    async emptyCart() {
      let idcart = localStorage.getItem("cartIdBKL");
      let token = localStorage.getItem("token");
      await axios.delete("emptycart?idcart=" + idcart, {
        headers: { Authorization: `Bearer ${token}` }
      }).then((resp) => {
        this.cart = null;
        this.idcart = null;
        this.$store.commit('SET_TOTAL_PRODSCART', 0);
        localStorage.removeItem("cartIdBKL");
      }).catch((error) => {
        this.$store.commit('SET_TOTAL_PRODSCART', 0);
        localStorage.removeItem("cartIdBKL");
        console.log(error);
      });
    }
  },
}
</script>

<style scoped>
.small{
  width: 80px;
}
.btn-empty-cart{
  font-size: 12px;
  text-decoration: none;
  color: #dedede;
  background-color: transparent;
  border: none;
}

.btn-empty-cart:hover{
  color: #e20036;
}

.heading-cart{
  font-weight: bold;
}

@media (max-width: 992px) {
  .heading-cart{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
</style>