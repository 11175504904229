<template>
  <section class="page_wrapper">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="single-product-slider" v-if="product.images?.length">
            <img class="image" :src="product.images?.[slide]?.imageUrl" alt=""/>
            <ol class="d-flex justify-content-center carousel">
              <li v-for="(image, index) in product.images">
                <img class="image-carousel" :src="product.images?.[index]?.imageUrl" v-on:click="changeIndex(index)"/>
              </li>
            </ol>
          </div>
          <div v-else>
            <img class="image" :src="base + 'images/placeholder.jpg'" alt=""/>
          </div>
        </div>
        <div class="col-md-6">
          <div class="single-product-details">
            <h2>
              <span v-if="typeof product.name !== 'undefined'">
                {{ product.name }}
              </span>
              <span v-else>
                {{ product.description }}
              </span>
            </h2>
            <div class="d-flex justify-content-between">
              <h4 class="product-price" v-if="product.discountprice"><del>{{ formatPrice(product.price) }} €</del> {{ formatPrice(product.discountprice) }} €</h4>
              <h4 class="product-price" v-else>{{ formatPrice(product.price) }} €</h4>
              <button class="addtofavourites" v-on:click="addToFavourites(product.id)">
                <font-awesome-icon v-if="checkFav"  class="redfav" :icon="['fas', 'heart']"/>
                <font-awesome-icon v-else :icon="['far', 'heart']"/>
              </button>
            </div>
            <div class="bikini" v-if="product.type === 'bikini'">
              <div class="product-top-size mt-4 mb-3">
                <span class="fw-bold">Select top size:</span><br>
                <div class="description_size mt-1">
                  <p>{{ product.toptext }}</p>
                </div>
                <template v-for="topsize in product.subproducts">
                  <div class="select-image" v-if="topsize.piece === 'top' && this.getQntSub(topsize.idProduct)">
                    <img class="mt-1 mb-3 bordersize" :src="base + 'images/size/size-' + topsize.size + '.png'" width="100"
                         v-bind:id="topsize.idProduct"
                         v-on:click="selectSize($event, 'topsize');">
                  </div>
                  <div class="select-image" v-else-if="topsize.piece === 'top'">
                    <img class="mt-1 mb-3 bordersize img-transparent" :src="base + 'images/size/size-' + topsize.size + '.png'" width="100"
                         v-bind:id="topsize.idProduct"
                         v-on:click="selectAlertEmail($event, topsize.idProduct, topsize.size, 'top');"
                         title="Out of stock">
                  </div>
                </template>
                <template v-if="showMailFormTop">
                  <p>This size is empty: receive an e-mail when it is back in stock</p>
                  <form @submit.prevent="sendEmail()">
                    <div class="input-group">
                      <input type="email" class="form-control" placeholder="Insert your e-mail" v-model="mailAlert" required>
                      <button class="btn btn-brand btn-nopadding" type="submit">NOTIFY ME</button>
                    </div>
                  </form>
                </template>
              </div>
              <div class="product-bottom-size mb-3">
                <span class="fw-bold">Select bottom coverage:</span><br>
                <div class="description_size mt-1">
                  <p>{{ product.bottomtext }}</p>
                </div>
                <div class="width-fit">
                  <template v-for="bottomsize in product.subproducts">
                    <div class="select-image" v-if="bottomsize.piece === 'bottom' && this.getQntSub(bottomsize.idProduct)">
                      <template v-if="bottomsize.size.includes('XS') || bottomsize.size.includes('S') || bottomsize.size.includes('M') || bottomsize.size.includes('L') || bottomsize.size.includes('UNICA')">
                        <img class="mt-1 bordersize" :class="{ key : key }" :src="base + 'images/size/size-' + bottomsize.size + '.png'" width="100"
                             v-bind:id="bottomsize.idProduct"
                             v-on:click="selectSize($event, 'bottomsize');">
                      </template>
                      <template v-else>
                        <div class="text-center small mt-1 mb-2">One size</div>
                        <img class="mt-1 bordersize" :class="{ key : key }" :src="base + 'images/size/size-' + bottomsize.size + '.png'" width="100"
                             v-bind:id="bottomsize.idProduct"
                             v-on:click="selectSize($event, 'bottomsize');">
                      </template>
                    </div>
                    <div class="select-image" v-else-if="bottomsize.piece === 'bottom'">
                      <template v-if="bottomsize.size.includes('XS') || bottomsize.size.includes('S') || bottomsize.size.includes('M') || bottomsize.size.includes('L')">
                        <img class="mt-1 bordersize img-transparent" :src="base + 'images/size/size-' + bottomsize.size + '.png'"
                             v-on:click="selectAlertEmail($event, bottomsize.idProduct, bottomsize.size, 'bottom');" width="100" title="Out of stock">
                      </template>
                      <template v-else>
                        <div class="text-center small mt-1 mb-2">One size</div>
                        <img class="mt-1 bordersize img-transparent" :src="base + 'images/size/size-' + bottomsize.size + '.png'"
                             v-on:click="selectAlertEmail($event, bottomsize.idProduct, bottomsize.size, 'bottom');" width="100" title="Out of stock">
                      </template>
                    </div>
                  </template>
                  <div v-if="showMailFormBottom" class="my-3">
                    <p>This size is empty: receive an e-mail when it is back in stock</p>
                    <form @submit.prevent="sendEmail()">
                      <div class="input-group">
                        <input type="email" class="form-control" placeholder="Insert your e-mail" v-model="mailAlert" required>
                        <button class="btn btn-brand btn-nopadding" type="submit">NOTIFY ME</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="size-length" v-else-if="product.type === 'size' || product.type === 'length'">
              <div class="product-top-size mt-3 mb-3">
                <span class="fw-bold">Select the size:</span><br>
                <div class="description_size mt-1">
                  <p>{{ product.toptext }}</p>
                </div>
                <template v-for="size in product.subproducts">
                  <div class="select-image" v-if="this.getQntSub(size.idProduct)">
                    <img class="mt-1 mb-3 bordersize" :src="base + 'images/size/size-' + size.size + '.png'" width="100"
                       v-bind:id="size.idProduct"
                       v-on:click="selectSize($event, 'topsize');">
                  </div>
                  <div class="select-image" v-else>
                    <img class="mt-1 mb-3 bordersize img-transparent" :src="base + 'images/size/size-' + size.size + '.png'"
                         v-on:click="selectAlertEmail($event, size.idProduct, size.size, 'product');"
                         width="100" title="Out of stock">
                  </div>
                </template>
                <template v-if="showMailForm">
                  <p>This size is empty: receive an e-mail when it is back in stock</p>
                  <form @submit.prevent="sendEmail()">
                    <div class="input-group">
                      <input type="email" class="form-control" placeholder="Insert your e-mail" v-model="mailAlert" required>
                      <button class="btn btn-brand btn-nopadding" type="submit">NOTIFY ME</button>
                    </div>
                  </form>
                </template>
              </div>
            </div>
            <div class="size-length" v-else-if="product.type === 'voucher'">
              <div class="product-top-size mt-3 mb-3">
                <span class="fw-bold">Select the {{product.type}}:</span><br>
                <div class="description_size mt-1">
                  <p>{{ product.toptext }}</p>
                </div>
                <select class="form-control no-label" v-model="selectId" v-on:change="selectPush()">
                  <template v-for="el in product.subproducts">
                    <option :value="el.idProduct" v-if="this.getQntSub(el.idProduct)">{{el.name.toUpperCase()}}</option>
                  </template>
                </select>
              </div>
            </div>
            <div class="size-length" v-else-if="product.type !== 'product'">
              <div class="product-top-size mt-3 mb-3">
                <span class="fw-bold">Select the {{product.type}}:</span><br>
                <div class="description_size mt-1">
                  <p>{{ product.toptext }}</p>
                </div>
                <select class="form-control no-label" v-model="selectId" v-on:change="selectPush()">
                  <template v-for="el in product.subproducts">
                    <option :value="el.idProduct" v-if="this.getQntSub(el.idProduct)">{{el.piece.toUpperCase()}}</option>
                  </template>
                </select>
              </div>
            </div>
            <form class="text-left clearfix" @submit.prevent="addToCart">
              <!--<div class="product-quantity mt-4">
                <span>Quantity:</span>
                <div class="product-quantity-slider">
                  <input type="number" min="1" max="" name="product-quantity" class="form-control" v-model="qnt">
                </div>
              </div>-->
              <div class="form-check mt-4">
                <label class="form-check-label fw-bold" for="gift">Gift box (€ 2,00)</label>
                <input type="checkbox" class="form-check-input" id="gift" name="gift" v-model="gift">
              </div>
              <br>
              <button type="submit" class="btn btn-brand" v-bind:disabled="checkQnts() && product.type === 'product'">Add To Bag</button>
              <br>
              <br>
              <template v-if="checkQnts() && product.type === 'product'">
                <p>This size is empty: receive an e-mail when it is back in stock</p>
                <form @submit.prevent="sendEmail()">
                  <div class="input-group">
                    <input type="email" class="form-control" placeholder="Insert your e-mail" v-model="mailAlert" required>
                    <button class="btn btn-brand btn-nopadding" type="submit">NOTIFY ME</button>
                  </div>
                </form>
              </template>
              <div class="accordion mt-5">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      Product details
                    </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body" v-html="product.description" v-if="product.type === 'bikini' || product.type === 'length' || product.type === 'size'"></div>
                    <div class="accordion-body" v-html="product.descriptionExtended" v-else></div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      Size guide
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <img class="img-fluid" :src="base + 'images/size-guide.png'">
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import router from "@/router";
import {PATH_APP} from "@/constants/constants";
import Swal from "sweetalert2";

export default {
  name: "ProductDetails",
  data() {
    return {
      product: [],
      stocks: [],
      size: null,
      slide: 0,
      subproducts: [],
      qnt : 1,
      gift : false,
      base : PATH_APP,
      checkFav : false,
      selectId : null, //select del prodotto
      idProductMail : null,//id del prodotto da inviare per essere avvisati
      nameSizeSelected: null,//nome della size selezionata per avviso restock email
      mailAlert : "",//la mail del v-model della form per essere avvisati del restock
      showMailFormTop : false,
      showMailFormBottom : false,
      showMailForm : false
    }
  },
  async mounted() {
    await this.getProduct();
    await this.getStock();
  },
  methods: {
    selectAlertEmail(event,idproduct,size,type){
      if(type === 'top'){
        this.showMailFormTop = true;
        this.showMailFormBottom = false;
      }else if(type === 'bottom'){
        this.showMailFormBottom = true;
        this.showMailFormTop = false;
      }else if(type === 'product'){
        this.showMailForm = true;
      }
      this.idProductMail = idproduct;
      this.nameSizeSelected = size;
      let selectedEl = document.getElementsByClassName('bordersize');
      for (let i = 0; i < selectedEl.length; i++) {
        selectedEl[i].classList.remove('activeBorder');
      }
      event.currentTarget.classList.add('activeBorder');
    },
    checkQnts(){//controlla se c'é la quantità di prodotto generica
      if(this.product.type !== 'product'){
        return ((this.subproducts.length < 2  && this.product.type === 'bikini') || (this.subproducts.length < 1 &&
            (this.product.type === 'size' || this.product.type === 'length' || this.product.type === 'color' || this.product.type === 'voucher')));
      }else if(this.product.type === 'product'){
        return this.stocks[0].quantity <= 0;
      }
    },
    getProduct() {
      axios.post("products", {
        "start": 0,
        "limit": 100,
        "ids": [this.$route.params.id],
        "type" : "website"
      }).then(response => {
        if(response.data.products.length) {
          this.product = response.data.products[0];
          this.idProductMail = this.product["id"];
          document.title = this.product.name ? this.product.name + " | Bikini Lovers" : this.product.description + " | Bikini Lovers";
          this.checkFavourites(this.product.id);
        }else{
          router.push({name: "not-found"});
        }
      }).catch(function (error) {
          console.log(error);
      });
    },
    getStock() {
      axios.post("stocks", {
        "start": 0,
        "limit": 100,
        "idProducts": [this.$route.params.id],
      }).then((response) => {
        this.stocks = response.data.stocks;
      }).catch((error) => {
        console.log(error);
      });
    },
    getQntSub(idsub) {
      let item = this.stocks.filter(item => item.idProduct === idsub);
      return item[0] && item[0].quantity > 0 ? item[0].quantity : 0;
    },
    formatPrice(value) {
      return Number(value)
          .toFixed(2)
          .replace(".", ",");
    },
    changeIndex(index) {
      this.slide = index;
    },
    //prendo l'id delle taglie e lo metto dentro la variabile subproducts
    selectSize(event, type) {
      //elimino il bordo dalle size non in stock selezionate e nascondo le form di avviso restock
      let selectedEl = document.getElementsByClassName('bordersize');
      for (let i = 0; i < selectedEl.length; i++) {
        selectedEl[i].classList.remove('activeBorder');
      }
      event.currentTarget.classList.add('activeBorder');
      this.showMailForm = false;
      this.showMailFormTop = false;
      this.showMailFormBottom = false;


      let i = 0;
      selectedEl = document.getElementsByClassName(type);
      for(i = 0; i < selectedEl.length; i++){
        selectedEl[i].classList.remove(type);
      }
      event.currentTarget.classList.add(type);
      this.subproducts = this.subproducts.filter(typesub => typesub.type !== type );
      this.subproducts.push({
        "id" : event.currentTarget.id,
        "type" : type
      });
    },
    validateEmail(email) {
      return String(email)
          .toLowerCase()
          .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    },
    async sendEmail() {
      if(this.mailAlert && this.validateEmail(this.mailAlert)) {
        await axios.post("notifyrestock", {
          "idproduct": this.idProductMail,//id del prodotto da monitorare per il restock
          "idparent" : this.idProductMail === this.product.id ? null : this.product.id,//id pubblico del compound se è un compound
          "name": this.product.name ? this.product.name + " " + this.nameSizeSelected : this.product.description,
          "email": this.mailAlert,
        }).then(() => {
          Swal.fire({
            title: "You will receive an e-mail when the product will be restocked.",
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        }).catch((error) => {
          Swal.fire({
            title: error.response.data.error ? error.response.data.error : "Something went wrong. Check data or try later.",
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
          });
        });
      }
    },
    selectPush(){
      this.subproducts = [];
      this.subproducts.push({id : this.selectId, type : this.product.type});
    },
    checkFavourites(idprod){
      let favs = localStorage.getItem("favsBKL");
      if(favs && favs !== "") {
        let index = favs.indexOf(idprod);
        index >= 0 ? this.checkFav = true : this.checkFav = false;
      }else{
        this.checkFav = false;
      }
    },
    addToFavourites(idprod){
      let favs = JSON.parse(localStorage.getItem("favsBKL"));
      if(!favs || favs === ""){
        localStorage.setItem("favsBKL",JSON.stringify([idprod]));
      }else{
        favs.indexOf(idprod) === -1 ? favs.push(idprod) : favs.pop(idprod);
        localStorage.setItem("favsBKL", JSON.stringify(favs));
      }
      this.checkFavourites(idprod);
    },
    addToCart() {
      //check delle selezioni del prodotto
      // se bikini le selezioni devono essere 2, 1 se altro, se è prodotto nessuna selezione
      if((this.product.type === 'bikini' && this.subproducts.length === 2) ||
        (this.subproducts.length === 1 && (this.product.type === 'size' ||
          this.product.type === 'length' || this.product.type === 'color' || this.product.type === 'voucher')) ||
          this.product.type === 'product') {
        this.$store.dispatch("addToCart", {
          "idproduct": this.product.id,
          "subproducts": this.subproducts,
          "qnt": this.qnt,
          "type": this.product.type ? this.product.type : "product",
          "gift": this.gift
        });
      }else{
        if(this.product.type === 'bikini'){
          Swal.fire({
            title: "ATTENTION",
            icon: 'error',
            html: "Devi selezionare la taglia TOP e BOTTOM!<br>You have to select TOP and BOTTOM size!",//text
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
            customClass: {
              title: "title-red",
              icon: "no-icon",
            }
          });
        }else{
          Swal.fire({
            title: "ATTENTION",
            html: "Devi selezionare la taglia!<br>You have to select the size!",//text
            icon: 'error',
            confirmButtonText: 'Ok',
            confirmButtonColor: '#674e42',
            customClass: {
              title: "title-red",
              icon: "no-icon",
            }
          });
        }
      }
    },
  }
}
</script>

<style scoped>

.image {
  width: 100%;
}

.carousel{
  margin-top: 20px !important;
  width: 100%;
}

.carousel li{
  float: left;
  margin: 0;
  padding: 0;
  max-width: 125px;
}

.image-carousel {
  border: 1px solid #dedede;
  padding: 5px;
  width: 100%;
  height: auto;
}

.image-carousel:hover{
  cursor: pointer;
}

.single-product-details {
  margin-top: 50px;
}

.single-product-slider {
  align-items: center;
  justify-content: center;
}

.select-image{
  display:inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
}

.bordersize {
  border: 1px solid #000;
}

.bordersize:hover{
  cursor:pointer;
}

.activeBorder{
  border: 3px solid #000;
}

.bottomsize, .topsize, .sizelength{
  border: 3px solid #000;
}

.item .active {
  width: 100%;
  max-width: 600px;
  margin: 20px;
  text-align: center;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.product-description p{
  padding: 0 !important;
  margin-left: 0 !important;
}

.img-transparent{
  opacity: 0.2;
}

.width-fit{
  width: fit-content;
}

.single-product-details .product-quantity > span{
  width: 68px;
}

.addtofavourites{
  font-size: 25px;
  background: #ffffff;
  border: none;
  padding: 0;
}

.redfav{
  color: #e20036;
}

.btn-nopadding{
  padding: 5px 18px 5px 18px !important;
  font-size: 13px;
}

.no-label{
  padding: 5px 15px 5px 15px !important;
}

</style>