<template>
  <div class="page_wrapper">
    <div class="container shopping">
      <div class="row">
        <div class="col-lg-12">
          <h4 class="widget-title">Payment details</h4>
          <BraintreePayment v-if="paymentType === 'braintree'"/>
          <PaypalPayment v-if="paymentType === 'paypal'" :order="this.order"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BraintreePayment from "@/components/BraintreePayment";
import PaypalPayment from "@/components/PaypalPayment";
import axios from "axios";
import router from "@/router";

export default {
  name: "CheckoutPayment",
  components : {
    BraintreePayment,
    PaypalPayment
  },
  data() {
    return {
      idorder : this.$route.params.idorder,
      paymentType: null,
      order : {}
    }
  },
  mounted(){
    //verifico che l'ordine non sia già stato pagato
    this.getOrder();
  },
  methods:{
    async getOrder(){
      let token = localStorage.getItem("token");
      await axios.get("orders/" + this.idorder,{
        headers: { Authorization: `Bearer ${token}` }
      }).then((resp) => {
        if(resp.data.order.type === 'website') {
          if (resp.data.order.status === 'pending') {
            this.paymentType = resp.data.order.payment.type;
            this.order = resp.data.order;
          } else {
            router.push("/message/6");
          }
        }else{
          router.push("/");
        }
      }).catch((error) => {
        console.log(error);
        router.push("/");
      });
    }
  }
}
</script>

<style scoped>

</style>