import {createRouter, createWebHistory} from 'vue-router';
import Home from '../views/Home';
import Search from "@/views/Search";
import Favourites from "@/views/Favourites";
import ProductDetails from "@/views/ProductDetails";
import Cart from "@/views/Cart";
import Resellers from "@/views/Resellers";
import Checkout from "@/views/Checkout";
import CheckoutPayment from "@/views/CheckoutPayment";
import Login from "@/views/Login";
import SignUp from "@/views/SignUp";
import RecoverPassword from "@/views/RecoverPassword";
import Message from '@/views/Message';
import UserArea from "@/views/UserArea";
import newPassword from "@/views/NewPassword";
import OrderDetails from "@/views/OrderDetails";
import store from "@/store";
import notFound from "@/views/NotFound";
//import Brand from '../views/Brand'
//import Contacts from '../views/Contacts'
//import ProdsCategory from "@/components/ProdsCategory";
//import PrivacyPolicy from "@/views/PrivacyPolicy";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta : {
            auth : false,
            title : "Bikinilovers | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
   {
        path: '/category/:categoryId',  // Utilizziamo un parametro dinamico per l'ID della categoria
        name: 'CategoryPage',
        component: Home,
        meta : {
            auth : false,
            title : "Bikinilovers | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/favourites',
        name: 'Favourites',
        component: Favourites,
        meta : {
            auth : false,
            title : "Bikinilovers | Your favourite products",
            description : "Your wishlist on Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/search',
        name: 'Search',
        component: Search,
        meta : {
            auth : false,
            title : " Search results | Bikinilovers | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/product/:id',
        name: 'ProductDetails',
        component: ProductDetails,
        meta : {
            auth : false,
            title : "Product | Bikini Lovers | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/cart',
        name: 'Cart',
        component: Cart,
        meta : {
            auth : false,
            title : "Cart | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }

    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: Checkout,
        meta : {
            auth : true,
            title : "Checkout | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/checkout/:idorder',
        name: 'Payment',
        component: CheckoutPayment,
        meta : {
            auth : true,
            title : "Payment | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/resellers',
        name: 'Resellers',
        component: Resellers,
        meta : {
            auth : false,
            title : "Resellers | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta : {
            auth : false,
            title : "Login | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: SignUp,
        meta : {
            auth : false,
            title : "Signup | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/recover',
        name: 'RecoverPassword',
        component: RecoverPassword,
        meta : {
            auth : true,
            title : "Password recovery | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/website/recover/:token',
        name: 'NewPassword',
        component: newPassword,
        meta : {
            auth : false,
            title : "New password | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/recover',
        name: 'RecoverPassword',
        component: RecoverPassword,
        meta : {
            auth : false,
            title : "Recover | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/message/:mex',
        name: 'Message',
        component: Message,
        meta : {
            auth : false,
            title : "Message | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/userarea',
        name: 'UserArea',
        component: UserArea,
        meta : {
            auth : true,
            title : "Your area | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    {
        path: '/orderdetails/:idorder',
        name: 'OrderDetails',
        component: OrderDetails,
        meta : {
            auth : true,
            title : "Order details | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },
    /*{
      path: '/brand',
      name: 'Brand',
      component: Brand,
      meta : {
          auth : false,
          title : "Brand | Not just a brand",
          description : "Official website of Bikini Lovers, not just a brand"
      }
  },*/
    /*{
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
        meta : {
            auth : false,
            title : "Contacts | Not just a brand",
            description : "Official website of Bikini Lovers, not just a brand"
        }
    },*/
    /*
  {
      path: '/returns',
      name: 'CambiResi',
      component: CambiResi,
      meta : {auth : false}
  },
  {
      path: '/terms-and-conditions',
      name: 'TermsAndConditions',
      component: TermsAndConditions,
      meta : {auth : false}
  },
  */
    /* {
       path: '/privacy-policy',
       name: 'PrivacyPolicy',
       component: PrivacyPolicy,
       meta : {
           auth : false,
           title : "Privacy policy | Not just a brand",
           description : "Official website of Bikini Lovers, not just a brand"
       }
   },*/
    {
       path: "/:pathMatch(.*)*",
       name: "not-found",
       component: notFound,
       meta : {
           auth : false,
           title : "Not found",
           description : "Not found"
       }
   }
];

//history: createWebHistory('/newsite/'),
//base: '/newsite/',

const router = new createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to,from, savedPosition) {
        if (to.name !== "Home" && to.name !== "CategoryPage") {
            return {top: 0};
        }else if(savedPosition){
            return savedPosition
        }
    }
});

router.beforeEach((to, from, next) => {
    store.getters.manageAuth;
    if(to.meta.title !== undefined) {
        document.title = to.meta.title;
        document.querySelector('meta[name="description"]').setAttribute("content", to.meta.description);
    } if(to.meta.auth && !store.getters.getAuth){
        //this.$store.dispatch("Logout");
        next('/login');
    } else {
        next();
    }
});

export default router;