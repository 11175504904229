<template>
  <div class="header">
    <div class="topbar">
      WORLDWIDE SHIPPING
    </div>
    <section class="top-header">
      <div class="container">
        <div class="row">
          <div class="d-flex justify-content-between">
            <div class="menu">
              <CmsMenu :sidebarmenu="sidebarmenu"/>
            </div>
            <div class="logo">
              <router-link to="/">
                <img class="logo-main scale-with-grid"
                     :src="base + '/images/logo.png'"
                     style="max-height: 77px;">
              </router-link>
            </div>
            <div v-if="menus != null">
              <router-link
                  class="nav-link"
                  v-for="(menuItem, index) in menus.menuitems"
                  :key="index"
                  :to="menuItem.link"
              >
                <!-- Aggiungi dropdown solo se ci sono figli -->
                <template v-if="menuItem.itemschild.length > 0">
                  <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                    {{ menuItem.name }}
                  </a>
                  <ul class="dropdown-menu" :class="{ show: openDropdownIndex === index }" @mouseleave="closeDropdown">
                    <li v-for="(childItem, childIndex) in menuItem.itemschild" :key="childIndex">
                      <router-link :to="childItem.link" class="nav-link dropdown-item">{{ childItem.name }}</router-link>
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <a class="nav-link">
                    {{ menuItem.name }}
                  </a>
                </template>
              </router-link>
            </div>
            <div class="cart">
              <ul class="top-menu">
                <router-link to="/cart">
                  <div class="icon-cart">
                    <font-awesome-icon icon="fa-bag-shopping"/>
                    <span class="badge badgecart">{{totalProductsCart}}</span>
                  </div>
                </router-link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {PATH_APP} from '@/constants/constants';
import axios from "axios";
import CmsMenu from "@/components/CmsMenu.vue";

export default {
  name: "Header",
  props: ['menus', 'sidebarmenu'],
  data(){
    return {
      base : PATH_APP
    }
  },
  components : {
    CmsMenu,
  },
  beforeMount() {
    this.getTotalProdsCart();
  },
  computed : {
    totalProductsCart(){
      return this.$store.getters.getStatusTotalProdsCart;
    }
  },
  methods : {
      async getTotalProdsCart(){
        let idcart = localStorage.getItem("cartIdBKL");
        let token = localStorage.getItem("token");
        await axios.get("cart?idcart=" + idcart, {
          headers: { Authorization: `Bearer ${token}` }
        }).then(resp => {
           this.$store.commit('SET_TOTAL_PRODSCART', resp.data.totalProdsCart);
        }).catch(function (error) {
          if(error.response.status === 404) {
            localStorage.removeItem("cartIdBKL");
          }else{
            console.log(error);
          }
        });
      }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  width: 100%;
  background: white;
  z-index:3
}

.topbar {
  background-color: #000;
  width: 100%;
  text-align: center;
  color: #ffffff;
  padding: 5px;
  overflow: hidden;
  font-size: 13px;
  font-weight: bold;
}

.top-menu {
  padding-top: 26px !important;
}

@media (max-width: 768px) {
  .top-menu {
    padding-top: 35px !important;
  }
}

.top-menu svg {
  color: #000;
  font-size: 28px;
}

.icon-cart{
  text-decoration: none;
}

.badgecart{
  display: block;
  background-color: #000;
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12px;
  padding: 0;
  top: -17px;
}

.nav-link {
  margin-top: 10px; /* Riduci lo spazio tra gli elementi del menu */
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

</style>