<template>
  <div :class="[data.columnb, data.classes]" v-html="data.text">
  </div>
</template>

<script>
export default {
  name: "Brand_",
  props: ["data"],
};
</script>

<style scoped>

</style>
