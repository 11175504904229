<template>
  <section class="page_wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 text-center">
          <h4>Page not found</h4>
          <br>
          <h4>Ops! Something went wrong.</h4>
          <br>
          <router-link to="/" class="btn btn-brand">Go to shop</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Notfound",
}
</script>

<style scoped>

</style>